import React from "react";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import { useFormikContext } from "formik";

const FormikTextField = ({ field, ...props }) => {
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
  } = useFormikContext();
  return (
    <TextField
      error={errors[field] && touched[field]}
      helperText={errors[field] && touched[field] && errors[field]}
      name={field}
      fullWidth
      onBlur={handleBlur}
      value={values[field] || ""}
      onChange={handleChange}
      color="secondary"
      {...props}
    />
  );
};

FormikTextField.propTypes = {
  field: PropTypes.string.isRequired,
  margin: PropTypes.string,
  ...TextField.propTypes,
};

FormikTextField.defaultProps = {
  margin: "normal",
};

export default FormikTextField;
