import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { graphql, useStaticQuery } from "gatsby";
import _ from "lodash";
import { makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Formik } from "formik";
import Typography from "@material-ui/core/Typography";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import api from "../api";
import AnimatedButton from "../components/animated-button";
import config from "../../config";
import * as Cart from "../models/cart";
import FormikTextField from "../components/formik-text-field";
import Toast from "../components/toast";
import { CHECKOUT_SCHEMA } from "../data/constants";
import Paragraph from "../components/typography/paragraph";
import * as eventLogger from "../components/eventLogger";

const useStyles = makeStyles(theme => ({
  form: {
    width: "50vw",
    maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down(767)]: {
      width: "90vw",
    },
  },
  row: {
    display: "flex",
    alignItems: "center",
  },
  shippingLabel: {
    "&:after": {
      width: 12,
      content: "''",
      display: "inline-block",
    },
    color: "#222",
    fontSize: "18px",
    marginRight: 80,
  },
  firstRow: {
    marginTop: 40,
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-end",
  },
  middleRow: {
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-end",
  },
  lastRow: {
    marginTop: 20,
    marginBottom: 5,
    display: "flex",
    justifyContent: "flex-end",
  },
  totalLabel: {
    color: "#222",
    fontSize: "18px",
    marginRight: 80,
  },
  totalPrice: {
    fontSize: "18px",
    color: "#222",
    fontWeight: 600,
  },
  spacing: {
    width: 40,
  },
  applyCode: {
    minWidth: 150,
    height: 40,
    marginBottom: -15,
  },
  checkoutButton: {
    marginTop: 40,
  },
  checkoutLoader: {
    top: "4px",
  },
}));

const getTotalPrice = (price, promoCode) => {
  if (!promoCode) {
    return price;
  }

  switch (promoCode.type) {
    case "percentage":
      return (1 - promoCode.value / 100) * price;
    default:
      return price;
  }
};

const CheckoutPage = ({ cart }) => {
  const classes = useStyles();
  const [checkingCode, setCheckingCode] = useState(false);
  const stripe = useRef(null);
  const [err, setErr] = useState(false);
  const [checkoutErr, setCheckoutMsg] = useState(false);
  const [promoCode, setPromoCode] = useState(null);

  const data = useStaticQuery(graphql`
    query {
      order: file(relativePath: { eq: "order.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  useEffect(() => {
    stripe.current = window.Stripe(config.stripePublicKey);
  }, []);

  const handleApplyCode = async code => {
    setCheckingCode(true);
    eventLogger.logAppliedPromoCode(code);

    try {
      const promo = await api.getPromoCode(code);
      if (!promo.code) {
        setErr(true);
        setPromoCode(null);
      } else {
        setPromoCode(promo.code);
      }
    } catch (e) {
      setErr(true);
      setPromoCode(null);
    } finally {
      setCheckingCode(false);
    }
  };

  let content;

  if (cart.length === 0) {
    content = <Paragraph>Whoops, your cart is empty!</Paragraph>;
  } else {
    const canvasPrice = getTotalPrice(cart[0].canvas.price, promoCode);

    content = (
      <Formik
        initialValues={{
          email: "",
          firstName: "",
          lastName: "",
          zipCode: "",
          firstLine: "",
          secondLine: "",
          city: "",
          state: "",
          promoCode: "",
        }}
        validationSchema={CHECKOUT_SCHEMA}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          eventLogger.logCheckoutClick();

          try {
            const item = cart[0];
            const { id } = await api.createCheckout({
              canvasId: item.canvas.id,
              contentUrl: item.contentImage.url,
              presetStyle: item.presetStyle,
              styleUrl: _.get(item.customStyle, "url", ""),
              email: values.email,
              firstName: values.firstName,
              lastName: values.lastName,
              promoCode: _.get(promoCode, "code", ""),
              isVertical:
                item.contentImage.originalHeight >
                item.contentImage.originalWidth,
              address: {
                firstLine: values.firstLine,
                secondLine: values.secondLine,
                city: values.city,
                state: values.state,
                zipCode: values.zipCode,
              },
            });

            stripe.current
              .redirectToCheckout({ sessionId: id })
              .then(result => {
                if (_.get(result, "error.message")) {
                  setCheckoutMsg(result.error.message);
                }
              });
          } catch (e) {
            console.error(e);
            setCheckoutMsg(true);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values }) => (
          <div className={classes.form}>
            <FormikTextField field="email" label="Email" />
            <div className={classes.row}>
              <FormikTextField field="firstName" label="First name" />
              <div className={classes.spacing} />
              <FormikTextField field="lastName" label="Last name" />
            </div>
            <FormikTextField
              field="firstLine"
              label="Street and number, P.O. box, c/o."
            />
            <FormikTextField
              field="secondLine"
              label="Apartment, suite, unit, building, floor, etc."
            />
            <FormikTextField field="city" label="City" />
            <FormikTextField field="state" label="State / Province / Region" />
            <FormikTextField field="zipCode" label="Zip Code" />
            <div className={classes.row}>
              <FormikTextField
                field="promoCode"
                label="Promo code"
                helperText={promoCode ? `Code valid: -${promoCode.value}%` : ""}
              />
              <div className={classes.spacing} />
              <AnimatedButton
                label="Apply code"
                id="apply-promo"
                disabled={!values.promoCode || checkingCode}
                loading={checkingCode}
                size={28}
                className={classes.applyCode}
                onClick={() => handleApplyCode(values.promoCode)}
              />
            </div>
            <div className={classes.firstRow}>
              <Typography className={classes.shippingLabel}>
                Shipping
              </Typography>
              <Typography className={classes.totalPrice}>$5.00</Typography>
            </div>
            <div className={classes.middleRow}>
              <Typography className={classes.totalLabel}>
                Canvas {promoCode ? `(-${promoCode.value}%)` : ""}
              </Typography>
              <Typography className={classes.totalPrice}>
                ${canvasPrice.toFixed(2)}
              </Typography>
            </div>
            <div className={classes.lastRow}>
              <Typography className={classes.totalLabel}>
                Order Total
              </Typography>
              <Typography className={classes.totalPrice}>
                ${(canvasPrice + 5).toFixed(2)}
              </Typography>
            </div>
            <AnimatedButton
              className={classes.checkoutButton}
              label="Checkout"
              id="checkout"
              onClick={handleSubmit}
              loading={isSubmitting}
              disabled={isSubmitting}
              loaderClassName={classes.checkoutLoader}
            />
          </div>
        )}
      </Formik>
    );
  }

  return (
    <Layout>
      <SEO title="Checkout" />
      <Toast
        setOpen={setErr}
        open={err}
        message="Invalid promo code"
        severity="error"
      />
      <Toast
        setOpen={setCheckoutMsg}
        open={checkoutErr}
        message="Something went wrong - we are working on it! Please try again later."
        severity="error"
      />
      <SimplePage
        title="Checkout"
        description="Complete your order."
        fluid={data.order.childImageSharp.fluid}
      >
        {content}
      </SimplePage>
    </Layout>
  );
};

CheckoutPage.propTypes = {
  cart: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  cart: Cart.getCart(state),
});

export default connect(mapStateToProps)(CheckoutPage);
