import amplitude from "amplitude-js";
import config from "../../config";

if (process.env.NODE_ENV === "production" && typeof window !== "undefined") {
  amplitude.getInstance().init(config.amplitudePublicKey);
}

export const logCustomStyleTooSmall = styleImage => {
  amplitude.getInstance().logEvent("Custom style too small", {
    width: styleImage.originalWidth,
    height: styleImage.originalHeight,
  });
};

export const logCustomStyleClick = () => {
  amplitude.getInstance().logEvent("Custom style click");
};

export const logStyleSelected = styleKey => {
  amplitude.getInstance().logEvent("Regular style click", { styleKey });
};

export const logImaginizeClick = styleKey => {
  amplitude.getInstance().logEvent("Imaginize click", { styleKey });
};

export const logOrderPrintsClick = (uploadedImage, styleKey) => {
  amplitude
    .getInstance()
    .logEvent("Order prints click", { uploadedImage, styleKey });
};

export const logCanvasSizeClick = size => {
  amplitude.getInstance().logEvent("Checkout size selected", size);
};

export const logBuyClick = item => {
  amplitude.getInstance().logEvent("Buy canvas click", item);
};

export const logAppliedPromoCode = code => {
  amplitude.getInstance().logEvent("Applied promo code", code);
};

export const logCheckoutClick = () => {
  amplitude.getInstance().logEvent("Final checkout click");
};
